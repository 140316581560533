function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

var messageSend = debounce(
  function (sender, $modal, $form) {
    var data = $form.getData();
    if (data.body === undefined || data.body === '' || data.body === null) {
      var $body = $modal.getBody();
      var $feedback = $body.find('.feedback');
      $feedback.removeClass('is-hidden');
      window.setTimeout(function () {
        $feedback.addClass('is-hidden');
      }, 5000);
    } else {
      $K.ajax.post({
        url: '/api/v1/messages',
        data: data,
        success: function (response) {
          $.event.trigger({
            type: 'messageSendSuccess',
            item: response,
            to: data.to,
          });
        },
        error: function (response) {
          console.log('error sending message');
          $.event.trigger({
            type: 'messageSendError',
            item: response,
          });
        },
      });
      this.modal.close();
    }
  },
  500, //500ms
  messageSend
);

(function ($K) {
  $K.add('module', 'sendmessage', {
    init: function (app, context) {
      this.app = app;
      this.context = context;

      // modal id
      this.modalId = 'sendMessageModal_' + performance.now() * Math.pow(10, 15);

      // default params
      var defaultParams = {
        role: 'parent',
        authenticated: false,
        expired: true,
        to: '',
        name: '',
      };

      this.params = context.getParams(defaultParams);
      this.checkParams();

      // define service
      this.modal = app.modal;
    },

    checkParams: function () {
      if (this.params.role === '') {
        this.params.role = 'parent';
      }

      if (this.params.authenticated === '') {
        this.params.authenticated = false;
      }

      if (this.params.expired === '') {
        this.params.expired = false;
      }
    },

    buildModal: function () {
      var modalHtml = ``;

      if (emailDeliveryStatus === 'UNDELIVERABLE') {
        modalHtml = `<div id="${this.modalId}" class="send-message-modal-body is-hidden">

        <div class="is-container-row is-inset-x-16 is-inset-y-32 is-inset-x-8-mobile is-inset-y-16-mobile">
          <h1 class="is-text-center text-purple">Please verify your email address</h1>
        </div>

        <div class="is-container-row is-inset-x-16 is-inset-y-32 is-inset-x-8-mobile is-inset-y-16-mobile">
          <div class="is-center">
            <p>Please confirm your email address by clicking on the link in the email message we sent you. 
            To resend the message, <a id="resendVerification" href='#' onclick='return resendValidationEmail();'>click here</a></p>
          </div>
        </div>

        </div>`;
      } else if (this.params.role === 'babysitter') {
        if (!this.params.authenticated) {
          modalHtml = `<div id="${this.modalId}" class="send-message-modal-body is-hidden">

              <div class="is-container-row is-inset-x-16 is-inset-y-32 is-inset-x-8-mobile is-inset-y-16-mobile">
                <h1 class="is-text-center text-purple">Hi! To apply for jobs, please sign up or login now!</h1>
              </div>

              <div class="is-container-row is-inset-x-16 is-inset-y-32 is-inset-x-8-mobile is-inset-y-16-mobile">
                <div class="is-center">
                  <a href="/carer/register" onclick="FAB.trackJoinClick('send_message');" class="button is-big">SIGN UP</a>
                </div>
                <div class="is-center">
                  <a href="/account/logon" onclick="FAB.trackLoginClick('send_message');" class="button is-big">LOGIN</a>
                </div>
              </div>

            </div>`;
        } else {
          modalHtml = `<div id="${this.modalId}" class="send-message-modal-body is-hidden">
              
              <form method="post" action="/message" target="ifrm_${this.modalId}">
                <input type="hidden" name="to" value="${this.params.to}" />              
                
                <div class="form-item">
                  <label>Your Message *</label>
                  <textarea name="body" rows="6" required autofocus></textarea>
                  <div class="feedback text-purple is-hidden is-inset-y-8 is-inset-y-4-mobile">You must enter a message to send</div>
                </div>
                
              </form>
              
              <iframe id="ifrm_${this.modalId}" name="ifrm_${this.modalId}" style="display:none;"></iframe>

            </div>`;
        }
      } else if (this.params.role === 'parent') {
        if (!this.params.authenticated) {
          modalHtml = `<div id="${this.modalId}" class="send-message-modal-body is-hidden">

              <div class="is-container-row is-inset-x-16 is-inset-y-32 is-inset-x-8-mobile is-inset-y-16-mobile">
                <h1 class="is-text-center text-purple">Hi! To contact babysitters and nannies, join or login now!</h1>
              </div>

              <div class="is-container-row is-inset-x-16 is-inset-y-32 is-inset-x-8-mobile is-inset-y-16-mobile">
                <div class="is-center">
                  <a href="/parent/register" class="button is-big">JOIN</a>
                </div>
                <div class="is-center">
                  <a href="/account/logon" class="button is-big" onclick="FAB.trackLoginClick('send_message');">LOGIN</a>
                </div>
              </div>

            </div>`;
        } else {
          if (this.params.expired) {
            modalHtml = `<div id="${this.modalId}" class="send-message-modal-body is-hidden">

                <div class="is-container-row is-inset-x-16 is-inset-y-32 is-inset-x-8-mobile is-inset-y-16-mobile">
                  <h1 class="is-text-center text-purple">Oh no! Your membership has expired! Please subscribe to contact babysitters.</h1>
                </div>

                <div class="is-container-row is-inset-x-16 is-inset-y-32 is-inset-x-8-mobile is-inset-y-16-mobile">
                  <div class="is-center">
                    <a href="/parent/payment" class="button is-big">SUBSCRIBE NOW!</a>
                  </div>
                </div>

              </div>`;
          } else {
            modalHtml = `<div id="${this.modalId}" class="send-message-modal-body is-hidden">
              
                <form method="post" action="/message" target="ifrm_${this.modalId}">
                  <input type="hidden" name="to" value="${this.params.to}" />              
                  
                  <div class="form-item">
                    <label>Your Message *</label>
                    <textarea name="body" rows="6" required autofocus></textarea>
                    <div class="feedback text-purple is-hidden is-inset-y-8 is-inset-y-4-mobile">You must enter a message to send</div>
                  </div>
                  
                </form>
                
                <iframe id="ifrm_${this.modalId}" name="ifrm_${this.modalId}" style="display:none;"></iframe>

              </div>`;
          }
        }
      }

      return modalHtml;
    },

    onclick: function (e, element, type) {
      if (type === 'sendmessage') {
        // Check to see if they have contact before
        var buttonText = $(element).html().trim();
        var startDelim = '<!--';
        var endDelim = '-->';
        if (buttonText.indexOf(startDelim) > -1) {
          // take them to the message
          var id = buttonText.substring(
            buttonText.indexOf(startDelim) + startDelim.length
          );
          id = id.substring(0, id.length - endDelim.length);
          window.location = '/message/' + id;
        } else {
          this.showModal();
        }
      }
    },

    showModal: function () {
      $K.dom('.send-message-modal-body').remove();
      $K.dom('body').append(this.buildModal());

      if (
        this.params.authenticated &&
        !this.params.expired &&
        this.params.to &&
        this.params.name &&
        emailDeliveryStatus != 'UNDELIVERABLE'
      ) {
        this.modal.open({
          title: `<h1 class="text-purple is-text-center">Contact ${this.params.name}</h1>`,
          target: `#${this.modalId}`,
          commands: {
            sendMessage: {
              title: 'SEND MESSAGE',
              classname: 'button is-big is-100',
            },
          },
        });
      } else {
        this.modal.open({
          target: `#${this.modalId}`,
        });
      }
    },

    onmessage: {
      modal: {
        opened: function (sender, $modal, $form) {},
        sendMessage: messageSend,
      },
    },
  });
})(Kube);
